.about-section {
  position: relative;
  background-image: url('../images/bg_city_1.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}


.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

/* Ensuring text content appears above the overlay */
.about-section>* {
  position: relative;
  z-index: 2;
}
